import React from "react";
import theme from "theme";
import { Theme, Link, Strong, Text, Hr, Box, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"accessibility-statement"} />
		<Helmet>
			<title>
				מפגש ברלין
			</title>
			<meta name={"description"} content={"מפגש ברלין - מסעדת המבורגרים ז'בוטינסקי 61 רמת גן"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/639068b75f45170024239f08/images/d30fe4d2d9faa0c3b7d882546672799889b0df11.00000710.png?v=2023-01-07T11:19:42.792Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/639068b75f45170024239f08/images/d30fe4d2d9faa0c3b7d882546672799889b0df11.00000710.png?v=2023-01-07T11:19:42.792Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/639068b75f45170024239f08/images/d30fe4d2d9faa0c3b7d882546672799889b0df11.00000710.png?v=2023-01-07T11:19:42.792Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/639068b75f45170024239f08/images/d30fe4d2d9faa0c3b7d882546672799889b0df11.00000710.png?v=2023-01-07T11:19:42.792Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/639068b75f45170024239f08/images/d30fe4d2d9faa0c3b7d882546672799889b0df11.00000710.png?v=2023-01-07T11:19:42.792Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/639068b75f45170024239f08/images/d30fe4d2d9faa0c3b7d882546672799889b0df11.00000710.png?v=2023-01-07T11:19:42.792Z"} />
		</Helmet>
		<Link href="/index" color="#000000" position="static" font="50px sans-serif">
			<Strong>
				X
			</Strong>
		</Link>
		<Section padding="150px 0 150px 0" lg-padding="80px 0 80px 0" quarkly-title="Content-14">
			<Override slot="SectionContent" flex-direction="row" />
			<Box
				min-width="100px"
				min-height="100px"
				display="flex"
				justify-content="space-around"
				lg-flex-direction="column"
			>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					align-items="center"
					width="50%"
					padding="0px 0px 0px 80px"
					justify-content="center"
					lg-padding="0px 0px 0px 0"
					lg-width="100%"
				>
					<Text
						margin="0px 0px 20px 0px"
						font="normal 400 56px/1.2 --fontFamily-serifGaramond"
						sm-font="normal 700 42px/1.2 --fontFamily-sans"
						color="--darkL1"
						lg-margin="0px 0px 35px 0px"
						text-align="center"
					>
						הצהרת נגישות
					</Text>
					<Hr
						min-height="10px"
						margin="0px 0px 20px 0px"
						width="50px"
						display="flex"
						justify-content="center"
						border-width="2px 0 0 0"
						border-color="--color-darkL1"
					/>
					<Text margin="0px 0px 25px 0px" font="normal 300 18px/1.5 --fontFamily-sans" color="#474e53" text-align="center">
						<br />
						הצהרת הנגישות עודכנה בתאריך 17/04/2024
						<br />
						<br />
						<br />
						מפגש ברלין, פועלת על מנת להנגיש את הארגון ואת אתר האינטרנט שברשותה לאנשים עם מוגבלות על מנת לקדם שוויון זכויות ושקיפות כלפי אנשים עם מוגבלות.
						<br />
						<br />
						<br />
						הסדרי נגישות במסעדה
						<br />
						<br />
						<br />
						זאב ז'בוטינסקי 61, רמת גן. שעות פעילות: ראשון עד שישי בשעות 11:00 – 01:00. הסדרי נגישות: חניית נכים בקרבת המסעדה, דרכי גישה ללא מכשולים, כיסאות נגישים, שירותי נכים, לולאת השראה, הקראת תפריט לפי הצורך. לפניה בנושאי נגישות בלבד -: Samchi279@gmail.com{" "}
						<br />
						<br />
						אתר האינטרנט של המסעדה{" "}
						<br />
						<br />
						<br />
						אתר האינטרנט של חברתינו מצוי בכתובת mifgashberlin.co.il הינו אתר תדמיתי בלבד. האתר נועד להכיר לגולש חלק מהשירותים הניתנים ללקוחותינו. האתר עצמו אינו מספק שירות ציבורי (או כל שירות אחר) אלא מפרסם חלק מסוגי השירותים הניתנים באמצעות החברה וללקוחותיה בלבד. יחד עם זאת אנו סבורים כי על כל עסק באשר הוא, לפעול באופן סביר להנגשת כל מידע שהוא לכל אדם. כפועל יוצא מכך אנו מתחייבים לנקוט בכל מאמץ סביר על מנת להנגיש כל מידע המוצג באתר.
						<br />
						<br />
						<br />
						נתקלת בבעיה כלשהי? אנא עדכנו אותנו על מנת שנוכל לתקן, לעדכן ולפעול לשיפור נגישות האתר. אנא פנו בנושא ללא דיחוי בנושא לרכז הנגישות של המשרד באחת מדרכי ההתקשרות המפורטות מטה.
						<br />
						<br />
						<br />
						מהות אתר אינטרנט נגיש
						<br />
						<br />
						<br />
						אתר אינטרנט נגיש, הינו אתר המאפשר לאדם עם מוגבלות, לגלוש באותה רמת יעילות והנאה כגולשים אחרים, תוך שימוש ביכולות המערכת עליה הוא פועל ובאמצעות טכנולוגיות מסייעות לנגישות .
						<br />
						<br />
						<br />
						ביצוע התאמות הנגישות באתר האינטרנט
						<br />
						<br />
						<br />
						אנו מתחייבים לביצוע כל מאמץ סביר אתר זה עומד בדרישות תקנות שוויון זכויות לאנשים עם מוגבלות (התאמות נגישות לשירות),התשע”ג-2013,בכפוף לסייגים שלהלן:
						<br />
						<br />
						<br />
						<br />
						<br />
						התאמות הנגישות בוצעו עפ”י המלצות התקן הישראלי (ת”י 5568) לנגישות תכנים באינטרנט ברמת AA ומסמך WCAG2.0 הבינלאומי.
						<br />
						<br />
						<br />
						הבדיקות נבחנו לתאימות הגבוהה ביותר עבור Chrome
						<br />
						<br />
						<br />
						האתר מספק מבנה סמנטי עבור טכנולוגיות מסייעות ותמיכה בדפוס השימוש המקובל להפעלה עם מקלדת בעזרת מקשי החיצים ו- , Enterמתפריטים וחלונות
						<br />
						<br />
						• מותאם לתצוגה בדפדפנים הנפוצים כאמור לעיל.
						<br />
						<br />
						• מותאם לתוכנת קורא מסך NVDA.-
						<br />
						<br />
						<br />
						האתר כשלעצמו אינו מספק שירות לציבור, אלא מעניק מידע אודות שירותים משפטיים המסופקים באופן פרטי ללקוחות המשרד בלבד.
						<br />
						<br />
						<br />
						באתר משולבות תמונות אווירה המוצגות למטרות נוי בלבד.
						<br />
						<br />
						<br />
						התאמות הנגישות באתר בוצעו בהתאם לסימן ג': שירותי האינטרנט בתקנות שוויון זכויות לאנשים עם מוגבלות (התאמות נגישות לשירות) התשע"ג 2013, לתקן הישראלי ת"י 5568 המבוסס על הנחיותWCAG 2.0 , האתר הונגש לרמה AA ובכפוף לשינויים והתאמות שבוצעו במסמך התקן הישראלי.
						<br />
						<br />
						<br />
						באתר זה הוטמע תוסף הנגישות enable המסייע בהנגשת האתר לבעלי מוגבלויות.
						<br />
						<br />
						<br />
						תוסף הנגישות כולל את סרגל ההנגשה ובו הפונקציות הרלוונטיות וניתן להשתמש בהן באופן הבא:
						<br />
						<br />
						<br />
						<br />
						<br />
						כפתור התאמת אתר המאפשר התאמת האתר ותגיות האתר עבור מכשירי עזר וטכנולוגיות עזר לבעלי מוגבלויות.
						<br />
						<br />
						<br />
						כפתור ניווט המאפשר ניווט גם בעזרת מקשי המקלדת למעבר נגיש בין הקישורים שבאתר.
						<br />
						<br />
						<br />
						כפתור השבתה המאפשר השבתת הבהובים ו/או אלמנטים נעים על המסך
						<br />
						<br />
						<br />
						כפתור מנו כרום המאפשר מעבר למצב מונוכרום שחור לבן עבור עיוורי צבעים
						<br />
						<br />
						<br />
						כפתור ספיה (גוון חום) המנגיש באמצעות מעבר לגווני חום.
						<br />
						<br />
						<br />
						כפתור שינוי ניגודיות גבוהה
						<br />
						<br />
						<br />
						כפתור שחור צהוב
						<br />
						<br />
						<br />
						כפתור היפוך צבעים
						<br />
						<br />
						<br />
						כפתור להדגשת תגי כותרת המדגיש בצורה ברורה את כל תגי הכותרות המופיעים באתר.
						<br />
						<br />
						<br />
						כפתור הנגשת קישורים באמצעותו ניתן להדגיש בצורה ברורה את כל הקישורים המופיעים באתר
						<br />
						<br />
						<br />
						כפתור תיאור חלופי לתמונות – באמצעותו ניתן להציג את התיאור החלופי של כל התמונות המופיעות באתר במעבר עכבר
						<br />
						<br />
						<br />
						כפתור המציג תיאור קבוע של התמונות באתר
						<br />
						<br />
						<br />
						כפתור ביטול שימוש בגופן קריא
						<br />
						<br />
						<br />
						כפתור הגדלת גודל הגופנים באתר
						<br />
						<br />
						<br />
						כפתור הקטנת גודל הגופנים באתר
						<br />
						<br />
						<br />
						כפתור הגדלת כל התצוגה לכ־200%
						<br />
						<br />
						<br />
						כפתור הקטנת כל התצוגה לכ־70%
						<br />
						<br />
						<br />
						כפתור הגדלת סמן העכבר
						<br />
						<br />
						<br />
						כפתור הגדלת סמן העכבר ושינוי צבעו לשחור
						<br />
						<br />
						<br />
						כפתור מצב קריאת האתר
						<br />
						<br />
						<br />
						כפתור המציג את הצהרת הנגישות
						<br />
						<br />
						<br />
						כפתור איפוס המבטל את הנגישות
						<br />
						<br />
						<br />
						כפתור שליחת משוב נגישות
						<br />
						<br />
						<br />
						כפתור שינוי שפת הסרגל והצהרת הנגישות בהתאם
						<br />
						<br />
						<br />
						בסרגל הנגישות קיימים שני סוגים של הגדלות לנוחיותכם, אך אם תרצו להגדיל עוד את האותיות תוכלו להשתמש בפונקציות המקלדת הבאות:
						<br />
						<br />
						<br />
						<br />
						<br />
						מקש Esc יפתח ויסגור את סרגל הנגישות
						<br />
						<br />
						<br />
						מקש Ctrl + יגדיל את הטקסט באתר
						<br />
						<br />
						<br />
						מקש Ctrl – יקטין את הטקסט באתר
						<br />
						<br />
						<br />
						מקש Ctrl 0 יחזיר את האתר לגדלו המקורי
						<br />
						<br />
						<br />
						מקש רווח (SPACE) יוריד את האתר כלפי מטה.
						<br />
						<br />
						<br />
						מקש F11 יגדיל את המסך לגודל מלא – לחיצה נוספת תקטין אותו חזרה.
						<br />
						<br />
						<br />
						אנו מחויבים להנגיש את אתר האינטרנט לכלל הגולשים הן ולרבות בעלי מוגבלויות. אנו פועלים בשקידה סבירה ובמאמץ מקסימאלי לוודא כי האתר שמיש ונגיש לכלל האוכלוסיה. יחד עם זאת ייתכן ותמצאו באתר אלמנטים שטרם הונגשו או שלא הונגשו די הצורך ע"י טכנולוגיה מתאימה באופן מיטבי, אנו מתחייבים לעשות את מירב המאמצים לשיפור חידוש והנגשת כל אלמנט ברמה המיטבית. במידה ונתקלתם בקושי בגלישה באתר ו/או בצפייה בתוכנו אנו מתנצלים ונשמח מאוד כי תפנו את תשומת ליבנו לכך .
						<br />
						<br />
						<br />
						האתר ייבדק ע"י רכז הנגישות אחת ל- 8 חודשים, ובנוסף לאחר כל שינוי שיערך באתר. הכל על מנת להבטיח את תחזוקת נגישות האתר.
						<br />
						<br />
						<br />
						שלבי ההנגשה בהם נמצא האתר
						<br />
						<br />
						<br />
						לקראת סוף חודש ספטמבר  2023 החלנו הליך של איפיון האתר מחדש ושינוי התכנים המופיעים בו, (אם נדרש) אנו מערכים שהנגשת האתר תושלם עד לתאריך ה30/04/2024 יובהר כי האתר אינו מספק כל שירות ציבורי למעט פרסום מידע  כללי באופן שיווקי אשר נועד להמחיש את תחומי פעילות החברה. באתר האינטרנט קיימת האפשרות להשארת פרטים לשם יצירת קשר טלפוני מנציג החברה. בכל מצב בו נמצאה פונקציה של אתר האינטרנט בלתי נגישה, ניתן לקבל את כל המידע המוצג באתר בשיחה טלפונית עם ממונה הנגישות, וכן ניתן להירשם באופן טלפוני אצל ממונה הנגישות לרשימת התפוצה.
						<br />
						<br />
						<br />
						דרכי פניה לבקשות, תקלות נגישות והצעות לשיפור:{" "}
						<br />
						<br />
						<br />
						במידה ומצאתם באתר האינטרנט בעיה בנושא הנגישות או שהנכם זקוקים עזרה, אתם מוזמנים לפנות אלינו דרך רכז הנגישות של הארגון – מר' ירון סמכי באחת הדרכים הבאות:
						<br />
						<br />
						<br />
						<br />
						<br />
						טל': 050-440-5333, (שעות פעילות בימים א-ה בין השעות 11:00 ל- 17:00).
						<br />
						<br />
						<br />
						בפניה טלפונית מחוץ לשעות הפעילות ו/או בהעדר מענה אנושי מכל סיבה, ניתן להשאיר הודעה במענה הקולי. בהודעה יש לומר בקול רם ובאופן ברור את שם הפונה, נושא הפניה ("בעניין נגישות"), ומספר טלפון לחזרה.
						<br />
						<br />
						<br />
						<br />
						<br />
						פניה בהודעת דוא"ל לכתובת: Samchi279@gmail.com  יש לכתוב בכותרת ההודעה את עניין הפניה ("בעניין נגישות"), ובתוכן ההודעה יש לפרט את נושא הפניה, ולהקליד בעברית את שם הפונה, מספר הטלפון אליו ניתן לחזור ופרטים נוספים ליצירת קשר.{"\n\n"}
					</Text>
				</Box>
			</Box>
		</Section>
		<Link
			font={"--capture"}
			font-size={"10px"}
			position={"fixed"}
			bottom={"12px"}
			right={"12px"}
			z-index={"4"}
			border-radius={"4px"}
			padding={"5px 12px 4px"}
			background-color={"--dark"}
			opacity={"0.6"}
			hover-opacity={"1"}
			color={"--light"}
			cursor={"pointer"}
			transition={"--opacityOut"}
			quarkly-title={"Badge"}
			text-decoration-line={"initial"}
			href={"https://quarkly.io/"}
			target={"_blank"}
		>
			Made on Quarkly
		</Link>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"639068b75f45170024239f06"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
			<script place={"endOfHead"} rawKey={"661fae3033a5a44ee91af3c8"} />
			<script place={"endOfBody"} rawKey={"661fb2710a02415681b09f45"}>
				{"<script src=\"https://cdn.enable.co.il/licenses/enable-L9106k5bc614ivu-0721-58225/init.js\"></script>"}
			</script>
		</RawHtml>
	</Theme>;
});